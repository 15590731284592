import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import hero from './hero.jpeg'
import heroSm from './hero-sm.jpeg'
import {
  VerticalLogo,
  HorizontalLogo,
  useMediaQuery,
  ShoppingCartButton,
  LanguageButton,
  SearchField,
  Image,
} from '../../elements'
import GoToShopButton from './GoToShopButton'

const Container = styled.section`
  position: relative;
  width: 100vw;
  height: ${window.innerHeight + 64}px;
`

const Header = styled.header`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 32px 128px;
  z-index: 10;

  & > :not(:last-child) {
    margin-right: 8px;
  }

  @media (max-width: 600px) {
    justify-content: flex-start;
    padding: 32px;

    & > :first-child {
      flex: 1;

      svg {
        max-width: 218px;
        max-height: 86px;
      }
    }
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  position: relative;
  height: ${window.innerHeight}px;

  @media (max-width: 600px) {
    padding: 64px 32px 0 32px;
  }
`

const Title = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 500px;
`

const H1 = styled.h1`
  margin: 40px 0 8px 0;
  font-family: inherit;
  font-weight: 900;
  font-size: 0.875rem;
  color: #ffffff;
  text-shadow: -1px 1px 2px #000000;
`

const Paragraph = styled.p`
  margin: 0;
  font-family: inherit;
  font-weight: 500;
  font-size: 0.875rem;
  color: #ffffff;
  text-align: center;
  text-shadow: -1px 1px 2px #000000;
`

const Search = styled(SearchField)`
  min-width: 450px;

  input {
    ::placeholder {
      text-align: center;
    }
  }

  @media (max-width: 600px) {
    width: 100%;
    min-width: 0;

    input {
      font-size: 0.875rem;
    }
  }
`

export default function Home() {
  const matches = useMediaQuery('(max-width: 600px)')
  const { t } = useTranslation()
  const src = matches ? heroSm : hero

  return (
    <Container>
      <Image src={src} />
      <div>
        <Header>
          {matches && (
            <div>
              <HorizontalLogo />
            </div>
          )}
          <ShoppingCartButton />
          <LanguageButton />
        </Header>
        <Content>
          <Title>
            {!matches && <VerticalLogo width="162" height="192" />}
            <H1>{t('common.title')}</H1>
            <Paragraph>{t('common.subtitle')}</Paragraph>
          </Title>
          <Search />
          <GoToShopButton />
        </Content>
      </div>
    </Container>
  )
}
