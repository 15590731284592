import React from 'react'

export default function VerticalLogo(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 204.8 248.2"
      {...props}
    >
      <path
        fill="#fbea1f"
        d="M124.1 94.9a31.2 31.2 0 1031.2 31.2 31.24 31.24 0 00-31.2-31.2M150 0a31.2 31.2 0 1031.2 31.2A31.24 31.24 0 00150 0M54.7 25.7a31.2 31.2 0 1031.2 31.2 31.24 31.24 0 00-31.2-31.2M0 248.1c.1-1.6.3-10.6.3-16.7v-5.7c0-4.7-.3-15-.3-16.2h5.4c-.1.9-.3 11-.3 15.7v5.8c0 4.1.2 16.1.3 17.2H0zm24.4-.3c-.5-.8-16.7-17.4-19-19.9.9-.9 16.2-16 17.5-17.4L26 214c-2 1.9-11.7 10.6-14.6 13.5 1.7 2 14.5 14.6 16.5 16.6zM43.8 231.4c0 5.4.2 15 .2 16.2h-5.2c.1-2.4.2-14.9.2-16.3v-5.8c0-.9-.2-14.2-.3-15.4h5.4c-.1 1-.3 9.1-.3 15.5zM59.9 224c0-1.1-.1-12.4-.3-13.9a114.94 114.94 0 0113.7.5c17.5 2.2 19.6 10 19.6 13.7 0 16.6-21.1 23.5-33.3 23.5.2-2.5.3-15.8.3-17.2zm4.8 19.1c14.6-1.3 22.9-9.9 22.9-17.4 0-8.1-9.1-11.8-22.9-11.6zM101.3 209c3.5.3 28.4 1.3 29.8 1.3v.1c-2.5 2.7-23.4 32.9-23.9 33.6 2.9 0 20.7-.2 22.2-.4l-.2 4.4c-1.3 0-27.8-.5-30.6-.5v-.1c2.4-2.4 23-32.1 24.3-33.9-2.1 0-20.2-.5-21.8-.5zM138.1 209c3.5.3 28.4 1.3 29.8 1.3v.1c-2.5 2.7-23.4 32.9-23.9 33.6 2.9 0 20.7-.2 22.2-.4l-.2 4.4c-1.3 0-27.8-.5-30.6-.5v-.1c2.4-2.4 23-32.1 24.3-33.9-2.1 0-20.2-.5-21.8-.5zM175 209c3.5.3 28.4 1.3 29.8 1.3v.1c-2.5 2.7-23.4 32.9-23.9 33.6 2.9 0 20.7-.2 22.2-.4l-.2 4.4c-1.3 0-27.8-.5-30.6-.5v-.1c2.4-2.4 23-32.1 24.3-33.9-2.1 0-20.2-.5-21.8-.5z"
      />
      <path
        fill="#83378d"
        d="M54.7 27.7a29.1 29.1 0 1029.1 29.1 29.06 29.06 0 00-29.1-29.1M63 49.1a3.6 3.6 0 113.6-3.6 3.52 3.52 0 01-3.6 3.6M124.1 96.9a29.15 29.15 0 1029.1 29.2 29.15 29.15 0 00-29.1-29.2m8.3 21.4a3.6 3.6 0 113.6-3.6 3.59 3.59 0 01-3.6 3.6M150 2.1a29.1 29.1 0 1029.1 29.1A29.06 29.06 0 00150 2.1m8.3 21.3a3.6 3.6 0 113.6-3.6 3.52 3.52 0 01-3.6 3.6"
      />
      <circle fill="#fbea1f" cx="62.9" cy="45.4" r="3.6" />
      <circle fill="#fbea1f" cx="158.2" cy="19.9" r="3.6" />
      <circle fill="#fbea1f" cx="132.3" cy="114.8" r="3.6" />
    </svg>
  )
}
