import React from 'react'
import {
  ApolloProvider as Provider,
  ApolloClient,
  InMemoryCache,
  HttpLink,
} from '@apollo/client'
import { useConfig } from '../providers'

export default function ApolloProvider({ children }) {
  const config = useConfig()
  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: new HttpLink({
      uri: config.graphqlUri,
    }),
  })
  return <Provider client={client}>{children}</Provider>
}
