import React from 'react'
import Hero from './Hero'
import PopularCategories from './PopularCategories'
import NewArrivals from './NewArrivals'

export default function Home() {
  return (
    <>
      <Hero />
      <PopularCategories />
      <NewArrivals />
    </>
  )
}
