import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { useShoppingCart } from '../providers'
import { Button, ShoppingCartIcon } from './'

const Container = styled(Button)`
  padding: 16px;
  font-weight: 700;

  & span {
    margin-right: 4px;
  }
`

export default function ShoppingCartButton() {
  const { state } = useShoppingCart()

  return (
    <Container as={Link} to="/shopping-cart">
      <span>{state.products.length}</span>
      <ShoppingCartIcon width={21} height={21} />
    </Container>
  )
}
