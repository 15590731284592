import React from 'react'
import { BrowserRouter, useLocation } from 'react-router-dom'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import {
  ConfigProvider,
  ApolloProvider,
  ThemeProvider,
  ShoppingCartProvider,
} from './components/providers'
import config from './config'
import './lang'
import { Main } from './components/views'
import { GlobalStyles } from './components/elements'

const stripePromise = loadStripe(config.stripe)

function ScrollHandler({ children }) {
  const { pathname } = useLocation()

  React.useEffect(() => window.scrollTo(0, 0), [pathname])
  return children
}

export default function App() {
  return (
    <ConfigProvider>
      <Elements stripe={stripePromise}>
        <ApolloProvider>
          <ThemeProvider>
            <GlobalStyles />
            <ShoppingCartProvider>
              <BrowserRouter>
                <ScrollHandler>
                  <Main />
                </ScrollHandler>
              </BrowserRouter>
            </ShoppingCartProvider>
          </ThemeProvider>
        </ApolloProvider>
      </Elements>
    </ConfigProvider>
  )
}
