import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Button } from '../../elements'

export default function GoToShopButton() {
  const { t } = useTranslation()

  return (
    <Button as={Link} to="/products">
      {t('homePage.goToShop')}
    </Button>
  )
}
