import React from 'react'
import styled, { css } from 'styled-components'
import { gql, useLazyQuery } from '@apollo/client'
import { Link, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { utils } from '@kidzzzlugano/core'
import { useConfig } from '../providers'
import { Typography, Square, Image, Button } from '../elements'

const Container = styled.div`
  max-width: 1114px;
  margin: 32px auto;

  @media (max-width: 1114px) {
    margin: 32px 32px;
  }
`

const Grid = styled.div`
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(5, minmax(0, 1fr));

  @media (max-width: 1114px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  @media (max-width: 600px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`

const Values = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 4px 0;

  & > div {
    text-align: center;
  }

  & > :not(:last-child) {
    margin-bottom: 8px;
  }
`

const SoldOut = styled.div`
  font-weight: 700;
  font-size: 0.875rem;
  color: #fa755a;
`

const Discount = styled.div`
  display: flex;
  align-items: center;

  & > :first-child {
    margin-right: 5px;
    text-decoration: line-through;
  }

  & > :nth-child(2) {
    font-weight: 700;
    color: #84368b;
  }
`

const LoadMoreButton = styled(Button)`
  ${({ disabled }) =>
    disabled &&
    css`
      color: #9d9d9d;
    `}
`

const GET_PRODUCTS = gql`
  query GetProducts($skip: Int!, $category: String) {
    getProducts(input: { skip: $skip, limit: 25, category: $category }) {
      id
      title {
        lang
        value
      }
      price
      discount
      brand
      images
      quantity
    }
  }
`

export default function Products() {
  const { t, i18n } = useTranslation()
  const [pagination, setPagination] = React.useState(true)
  const { assets } = useConfig()
  const params = useParams()
  const [getProducts, { loading, error, data, fetchMore }] = useLazyQuery(
    GET_PRODUCTS,
  )
  const category = params?.category?.replace(/-/gi, ' ').toLowerCase()

  React.useEffect(() => {
    setPagination(true)
    getProducts({
      variables: {
        skip: 0,
        category,
      },
    })
  }, [category, getProducts])

  if (error) {
    return <Container>Error: {error.message}</Container>
  }

  if ((loading && !data?.getProducts) || !data?.getProducts) {
    return <Container>Loading...</Container>
  }

  function handleLoadMore() {
    fetchMore({
      variables: {
        skip: data.getProducts.length,
        category,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult?.getProducts?.length) {
          setPagination(false)
          return prev
        }

        return {
          ...prev,
          getProducts: [...prev.getProducts, ...fetchMoreResult.getProducts],
        }
      },
    })
  }

  const title = category
    ? t(`menu.${params.category}`)
    : t('productsPage.products')

  const products = data.getProducts.map(product => ({
    ...product,
    title: product.title.find(title => title.lang === i18n.language).value,
    price: `${utils.formatMoneyToString(product.price)} CHF`,
    priceWithDiscount:
      product.discount &&
      `${utils.formatMoneyToString(
        product.price - (product.price / 100) * product.discount,
      )} CHF`,
    src: product.images[0],
  }))

  return (
    <Container>
      <div
        css={`
          display: flex;
          justify-content: center;
          margin-bottom: 64px;
        `}
      >
        <Typography variant="h2" as="h2">
          {title}
        </Typography>
      </div>
      <Grid>
        {products.map(product => (
          <Link key={product.id} to={`/product/${product.id}`}>
            <Square>
              <Image src={`${assets}${product.src}`} />
            </Square>
            <Values>
              <Typography variant="title">{product.brand}</Typography>
              <Typography variant="subtitle1">{product.title}</Typography>
              {product.priceWithDiscount ? (
                <Discount>
                  <Typography variant="subtitle2">{product.price}</Typography>
                  <Typography variant="subtitle2">
                    {product.priceWithDiscount}
                  </Typography>
                </Discount>
              ) : (
                <Typography variant="subtitle2">{product.price}</Typography>
              )}
              {!product.quantity && <SoldOut>{t('common.soldOut')}</SoldOut>}
            </Values>
          </Link>
        ))}
      </Grid>
      <div
        css={`
          display: flex;
          justify-content: center;
          padding: 64px 0;
          border-bottom: 1px solid #e3e3e3;
        `}
      >
        {pagination && (
          <LoadMoreButton disabled={loading} onClick={handleLoadMore}>
            {t(loading ? 'productsPage.loading' : 'productsPage.loadMore')}
          </LoadMoreButton>
        )}
      </div>
    </Container>
  )
}
