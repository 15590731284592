import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Button } from './'

const Container = styled(Button)`
  min-width: 53px;
  height: 53px;
  padding: 0;
  font-weight: 700;
  font-size: 1rem;
  border-radius: 100%;
`

export default function LanguageButton() {
  const { i18n } = useTranslation()
  const lang = i18n.language === 'en' ? 'it' : 'en'

  return (
    <Container onClick={() => i18n.changeLanguage(lang)}>
      {lang.toUpperCase()}
    </Container>
  )
}
