import React from 'react'
import styled, { css } from 'styled-components'
import { gql, useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { useParams, Link } from 'react-router-dom'
import { utils } from '@kidzzzlugano/core'
import { useConfig, useShoppingCart } from '../providers'
import {
  Typography,
  Square,
  Image,
  ArrowPrevIcon,
  ArrowNextIcon,
  ShoppingCartIcon,
  Select as BaseSelect,
  Button as BaseButton,
  ProductPreview,
  AddIcon,
  CloseIcon,
} from '../elements'

const Container = styled.div`
  max-width: 1114px;
  margin: 32px auto;

  @media (max-width: 1114px) {
    margin: 32px 32px;
  }
`

const Content = styled(Container)`
  display: grid;
  gap: 32px;
  grid-template-columns: repeat(2, minmax(0, 1fr));

  @media (max-width: 600px) {
    grid-template-columns: minmax(0, 1fr);
  }
`

const GalleryLayout = styled.div`
  display: grid;
  gap: 8px;
  grid-template-areas:
    'cover cover cover cover cover'
    '. thumbnail-0 thumbnail-1 thumbnail-2 .';
`

const Cover = styled(Square)`
  grid-area: cover;
`

const Thumbnail = styled(Square)`
  grid-area: ${({ index }) => `thumbnail-${index}`};
`

const PrevButton = styled(ArrowPrevIcon).attrs({ width: 40, hight: 40 })`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
`

const NextButton = styled(ArrowNextIcon).attrs({ width: 40, hight: 40 })`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
`

const Title = styled(Typography)`
  margin: 0 0 36px 0;
  font-weight: 500;
  font-size: 2.375rem;
  line-height: 1.25;
  color: #84368b;
`

const Price = styled(Typography)`
  margin: 0 0 36px 0;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1;
  color: #000;
`

const Discount = styled(Price)`
  display: flex;
  align-items: center;

  & > :first-child {
    font-weight: 500;
    margin-right: 5px;
    text-decoration: line-through;
  }

  & > :nth-child(2) {
    color: #84368b;
  }
`

const Description = styled(Typography)`
  margin: 0 0 36px 0;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5;
  color: #000;
`

const Label = styled(Typography)`
  margin: 0 0 8px 0;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1;
  color: #000;
`

const Value = styled(Typography)`
  margin: 0 0 36px 0;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25;
  color: #000;
`

const SoldOut = styled(Label)`
  color: #fa755a;
`

const Related = styled.div`
  margin: 64px 32px;
  padding: 32px 0 64px 0;
  border-bottom: 1px solid #e3e3e3;
  border-top: 1px solid #e3e3e3;
`

const Center = styled.div`
  display: flex;
  justify-content: center;
  padding: 50px 32px;
`

const Select = styled(BaseSelect)`
  select {
    width: 50%;
  }

  @media (max-width: 900px) {
    select {
      width: 100%;
    }
  }
`

const Controls = styled.div`
  display: grid;
  gap: 4px;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);

  @media (max-width: 900px) {
    gap: 16px;
    grid-template-columns: minmax(0, 1fr);
  }
`

const SubmitButton = styled(BaseButton)`
  width: 100%;
  padding: 16px 0;

  svg {
    margin-left: -24px;
  }

  ${({ selected }) =>
    selected &&
    css`
      color: #fff;
      background-color: #84368b;

      svg {
        margin-left: -0px;
        fill: #fff;
      }
    `}
`

const GoToCartButton = styled(SubmitButton)`
  svg {
    width: 24px;
    height: 24px;
    margin: 0 6px 0 -8px;
  }
`

const GET_PRODUCT = gql`
  query GetProductAndCategories($id: ID!) {
    getProduct(id: $id) {
      id
      title {
        lang
        value
      }
      description {
        lang
        value
      }
      brand
      price
      discount
      quantity
      images
      categories {
        name
      }
      variants {
        options {
          name
        }
        values {
          key
          quantity
        }
      }
      related {
        id
        title {
          lang
          value
        }
        description {
          lang
          value
        }
        brand
        price
        discount
        images
      }
    }
  }
`

function rotateArray(array, n, k) {
  const result = [...array]

  for (let i = 0; i < k; i++) {
    const x = result[0]
    for (let j = 0; j < n - 1; j++) {
      result[j] = result[j + 1]
    }
    result[n - 1] = x
  }

  return result
}

function Gallery({ images }) {
  const { assets } = useConfig()
  const [cover, setCover] = React.useState(0)

  const src = images[cover]
  const thumbnails = rotateArray(
    images.map((image, i) => ({ src: image, index: i })),
    images.length,
    cover,
  )
    .filter(image => image.src !== src)
    .slice(0, 3)

  return (
    <GalleryLayout>
      <Cover>
        <Image src={`${assets}${src}`} />
        <PrevButton
          onClick={() => setCover(utils.wrap(cover - 1, 0, images.length))}
        />
        <NextButton
          onClick={() => setCover(utils.wrap(cover + 1, 0, images.length))}
        />
      </Cover>
      {thumbnails.map((image, index) => (
        <Thumbnail
          key={index}
          index={index}
          onClick={() => setCover(image.index)}
        >
          <Image src={`${assets}${image.src}`} />
        </Thumbnail>
      ))}
    </GalleryLayout>
  )
}

function FormControls({ id, variant }) {
  const { t } = useTranslation()
  const { state, dispatch } = useShoppingCart()
  const selected = !!state.products.find(product => {
    return variant
      ? product.id === id && product.variant === variant
      : product.id === id
  })
  const props = selected
    ? {
        type: 'button',
        selected: true,
        onClick: e => {
          e.preventDefault()
          dispatch({ type: 'remove', id, variant })
        },
      }
    : {}

  return (
    <Controls>
      <SubmitButton {...props}>
        {selected ? (
          <>
            <CloseIcon />
            <span>{t('productPage.removeFromCart')}</span>
          </>
        ) : (
          <>
            <AddIcon />
            <span>{t('productPage.addToCart')}</span>
          </>
        )}
      </SubmitButton>
      {selected && (
        <GoToCartButton as={Link} to="/shopping-cart">
          <ShoppingCartIcon />
          <span>{t('productPage.goToCart')}</span>
        </GoToCartButton>
      )}
    </Controls>
  )
}

function ProductPrice({ price, discount }) {
  if (discount) {
    return (
      <Discount>
        <div>{`${utils.formatMoneyToString(price)} CHF`}</div>
        <div>{`${utils.formatMoneyToString(discount)} CHF`}</div>
      </Discount>
    )
  }

  return <Price>{`${utils.formatMoneyToString(price)} CHF`}</Price>
}

function ProductForm({ product, children }) {
  const { t } = useTranslation()
  const { dispatch } = useShoppingCart()
  const [quantity, setQuantity] = React.useState(1)

  const price = product.price * quantity
  const discount =
    product.discount &&
    (product.price - (product.price / 100) * product.discount) * quantity
  const options = Array.from({ length: product.quantity }).map((_, i) => i + 1)

  function handleSubmit(e) {
    e.preventDefault()
    dispatch({
      type: 'add',
      product: {
        id: product.id,
        brand: product.brand,
        title: product.titles,
        price: product.price,
        discount: product.discount,
        quantity,
        total: product.quantity,
        image: product.images[0],
        categories: product.categories,
      },
    })
  }

  return (
    <>
      <ProductPrice price={price} discount={discount} />
      {children}
      <form onSubmit={handleSubmit}>
        <Select>
          <label>{t('common.quantity')}</label>
          <select value={quantity} onChange={e => setQuantity(e.target.value)}>
            {options.map(value => (
              <option key={value} value={value}>
                {`${value}x`}
              </option>
            ))}
          </select>
        </Select>
        <FormControls id={product.id} />
      </form>
    </>
  )
}

function VariantForm({ product, children }) {
  const { t } = useTranslation()
  const { dispatch } = useShoppingCart()
  const [selections, setSelections] = React.useState(() => {
    const selections = []
    let node = product.options

    while (!node.__typename) {
      const [first] = Object.keys(node)
      selections.push(first)
      node = node[first]
    }

    return selections
  })
  const [quantity, setQuantity] = React.useState(1)

  const variant = selections.join('/')
  const values = utils.get(product.options, selections)
  const totalPrice = product.price * quantity
  const totalPriceWithDiscount =
    product.discount &&
    (product.price - (product.price / 100) * product.discount) * quantity
  const quantityOptions = Array.from({ length: values.quantity }).map(
    (_, i) => i + 1,
  )

  function handleSelectionsChange(index, option) {
    const change = [...selections]
    change[index] = option

    let i = 0
    let node = product.options

    while (i < change.length) {
      const next = node[change[i]]

      if (!next) {
        const [first] = Object.keys(node)
        change[i] = first
      }

      node = next
      i++
    }

    setSelections(change)
  }

  function handleSubmit(e) {
    e.preventDefault()
    dispatch({
      type: 'add',
      product: {
        id: product.id,
        brand: product.brand,
        title: product.titles,
        price: product.price,
        discount: product.discount,
        quantity,
        total: values.quantity,
        image: product.images[0],
        variant,
        labels: product.labels,
        categories: product.categories,
      },
    })
  }

  return (
    <>
      <ProductPrice price={totalPrice} discount={totalPriceWithDiscount} />
      {children}
      <form onSubmit={handleSubmit}>
        <VariantSelect
          index={0}
          labels={product.labels}
          options={product.options}
          values={selections}
          onChange={handleSelectionsChange}
        />
        <Select>
          <label>{t('common.quantity')}</label>
          <select value={quantity} onChange={e => setQuantity(e.target.value)}>
            {quantityOptions.map(value => (
              <option key={value} value={value}>
                {`${value}x`}
              </option>
            ))}
          </select>
        </Select>
        <FormControls id={product.id} variant={variant} />
      </form>
    </>
  )
}

function VariantSelect({ index, options, labels, values, onChange }) {
  const keys = Object.keys(options)
  const value = values[index]
  const label = labels[index]

  return (
    <>
      <Select>
        <label>{label}</label>
        <select value={value} onChange={e => onChange(index, e.target.value)}>
          {keys.map(value => (
            <option key={value} value={value}>
              {value}
            </option>
          ))}
        </select>
      </Select>
      {options[value] && !options[value].__typename && (
        <VariantSelect
          index={index + 1}
          labels={labels}
          options={options[value]}
          values={values}
          onChange={onChange}
        />
      )}
    </>
  )
}

export default function Product() {
  const { t, i18n } = useTranslation()
  const { id } = useParams()
  const { loading, error, data } = useQuery(GET_PRODUCT, { variables: { id } })

  if (error) {
    return <Container>Error: {error.message}</Container>
  }

  if (loading || !data?.getProduct) {
    return <Container>Loading...</Container>
  }

  const product = {
    ...data.getProduct,
    titles: data.getProduct.title,
    title: data.getProduct.title.find(({ lang }) => lang === i18n.language)
      .value,
    description: data.getProduct.description
      .find(({ lang }) => lang === i18n.language)
      .value.split('\n'),
    quantities: Array.from({ length: data.getProduct.quantity }).map(
      (_, i) => `${i + 1}x`,
    ),
    labels: data.getProduct.variants?.options.map(
      ({ name }) => `${name.charAt(0).toUpperCase()}${name.slice(1)}`,
    ),
    options: data.getProduct.variants?.values?.reduce((root, value) => {
      if (!value.quantity) {
        return root
      }

      const path = value.key.split('/')
      return utils.set(root, path, value)
    }, {}),
    related: data.getProduct.related,
  }

  const Notes = (
    <>
      <Description>
        {product.description.map((text, i) => (
          <div key={i}>{text}</div>
        ))}
      </Description>
      <Label>{t('productPage.brand')}</Label>
      <Value>{product.brand}</Value>
    </>
  )

  const Form =
    product.options && !!Object.keys(product.options).length ? (
      <VariantForm product={product}>{Notes}</VariantForm>
    ) : (
      <ProductForm product={product}>{Notes}</ProductForm>
    )

  return (
    <>
      <Content>
        {product.images?.length && <Gallery images={product.images} />}
        <div>
          <Title>{product.title}</Title>
          {product.quantity ? (
            Form
          ) : (
            <>
              <ProductPrice price={product.price} discount={product.discount} />
              {Notes}
              <SoldOut>{t('common.soldOut')}</SoldOut>
            </>
          )}
        </div>
      </Content>
      <Related>
        <Center>
          <Typography variant="h2" as="h2">
            {t('common.relatedItems')}
          </Typography>
        </Center>
        <ProductPreview data={product.related} />
      </Related>
    </>
  )
}
