import styled from 'styled-components'
import arrow from './arrow.png'

const Select = styled.div`
  label {
    display: block;
    margin: 0 0 8px 0;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1;
    color: #000;
  }

  select {
    appearance: none;
    width: 50%;
    padding: 16px 32px 16px 24px;
    margin: 0 0 32px 0px;
    font-family: inherit;
    font-weight: 500;
    font-size: 0.875rem;
    color: #000;
    background: transparent url(${arrow}) no-repeat right 16px center;
    border: 1px solid #c6c6c6;
    border-radius: 32px;

    &:focus {
      outline: none;
    }
  }
`

export default Select
