import React from 'react'
import styled, { css } from 'styled-components'
import { gql, useQuery } from '@apollo/client'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { utils } from '@kidzzzlugano/core'
import { useShoppingCart, useConfig } from '../providers'
import {
  Typography,
  Square as BaseSquare,
  Image,
  ProductPreview,
  Select as BaseSelect,
  Button,
  CloseIcon,
  ShippingIcon,
  useMediaQuery,
} from '../elements'

const Container = styled.div`
  max-width: 1114px;
  margin: 32px auto;

  @media (max-width: 1114px) {
    margin: 32px 32px;
  }
`

const Related = styled.div`
  margin: 64px 32px;
  padding: 32px 0 64px 0;
  border-bottom: 1px solid #e3e3e3;
`

const Center = styled.div`
  display: flex;
  justify-content: center;
  padding: 50px 32px;
`

const Grid = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(6, 1fr);
  place-items: center;
  padding: 16px 0;

  ${({ line }) =>
    line &&
    css`
      border-bottom: 1px solid #e3e3e3;
    `}

  ${({ error }) =>
    error &&
    css`
      border: 1px solid #fa755a;
    `}

  @media (max-width: 600px) {
    grid-template-columns: 30fr 40fr 20fr 10fr;
    grid-template-areas:
      'image values values action'
      'image quantity total total';
    place-items: start;
  }
`

const Header = styled(Typography)`
  padding: 8px 0;
  font-weight: 700;
  font-size: 0.75rem;
  color: #a6a6a6;
`

const Square = styled(BaseSquare)`
  @media (max-width: 600px) {
    grid-area: image;
  }
`

const Values = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 4px 0;

  & > div {
    text-align: center;
  }

  & > :not(:last-child) {
    margin-bottom: 6px;
  }

  @media (max-width: 600px) {
    grid-area: values;
    align-items: start;
  }
`
const Variants = styled.div`
  & > :not(:last-child) {
    margin-bottom: 6px;
  }

  @media (max-width: 600px) {
    display: flex;

    & > :not(:last-child) {
      margin-right: 6px;
    }
  }
`

const Variant = styled.div`
  font-family: inherit;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1;
  color: #000;

  & span:first-child {
    font-weight: 700;
  }
`

const SoldOut = styled(Typography)`
  font-weight: 500;
  font-size: 0.875rem;
  color: #fa755a;
`

const Select = styled(BaseSelect)`
  width: auto;

  select {
    width: auto;
    margin: 0;
  }

  @media (max-width: 600px) {
    grid-area: quantity;
  }
`

const Action = styled.div`
  @media (max-width: 600px) {
    grid-area: action;
    align-self: start;
    justify-self: end;
  }
`

const CloseButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  background-color: #84368b;

  svg {
    width: 18px;
    height: 18px;
    fill: #fff;
  }
`

const ProductTotal = styled.div`
  @media (max-width: 600px) {
    grid-area: total;
    justify-self: start;
    align-self: start;

    & > div:first-child {
      margin-bottom: 6px;
    }
  }
`

const ShippingInfo = styled.div`
  display: flex;
  align-items: center;
  padding: 32px;
  margin-bottom: 8px;
  background-color: #f9f9f9;

  svg {
    width: 48px;
    height: 48px;
    flex-shrink: 0;
    fill: #84368b;
  }

  div {
    margin: 0 0 0 32px;
    font-family: inherit;
    font-weight: 500;
    font-size: 1rem;
    text-align: left;
    line-height: 1.5;
  }

  @media (max-width: 600px) {
    flex-direction: column;

    div {
      margin: 8px 0 0 0;
      text-align: center;
    }
  }
`

const Total = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 32px;
  background-color: #f9f9f9;

  @media (max-width: 600px) {
    align-items: center;

    button {
      width: 100%;
    }
  }
`

const TotalValue = styled(Typography)`
  display: flex;
  align-items: flex-end;
  margin-bottom: 8px;
  text-align: center;

  & > div:first-child {
    margin: 0 4px 2px 0;
  }

  & > div:last-child {
    font-weight: 700;
    font-size: 2rem;
    line-height: 1;
  }

  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

const ShippingText = styled(Typography)`
  margin-bottom: 24px;
  text-align: center;
`

const GET_PRODUCTS_BY_CATEGORIES = gql`
  query GetProductsByCategories($categories: [String!]!) {
    getProductsByCategories(categories: $categories, skip: 0, limit: 10) {
      id
      title {
        lang
        value
      }
      description {
        lang
        value
      }
      brand
      price
      discount
      quantity
      images
    }
  }
`

function RelatedProducts({ categories }) {
  const { t } = useTranslation()
  const { loading, error, data } = useQuery(GET_PRODUCTS_BY_CATEGORIES, {
    variables: { categories },
  })

  if (error || loading || !data.getProductsByCategories) {
    return null
  }

  return (
    <Related>
      <Center>
        <Typography variant="h2" as="h2">
          {t('common.relatedItems')}
        </Typography>
      </Center>
      <ProductPreview data={data.getProductsByCategories} />
    </Related>
  )
}

function Cart({ products: data }) {
  const { t, i18n } = useTranslation()
  const { assets } = useConfig()
  const { dispatch } = useShoppingCart()
  const matches = useMediaQuery('(max-width: 600px)')

  const total = data.reduce((total, product) => {
    total += product.discount
      ? product.quantity *
        (product.price - (product.price / 100) * product.discount)
      : product.quantity * product.price
    return total
  }, 0)
  const shipping = total > 19500 ? 0 : 1400
  const products = data.map(product => {
    const title = product.title.find(({ lang }) => lang === i18n.language).value
    const price = `${utils.formatMoneyToString(product.price)} CHF`

    if (!product.discount) {
      return {
        ...product,
        title,
        price,
        totalPrice: `${utils.formatMoneyToString(
          product.price * product.quantity,
        )} CHF`,
      }
    }

    const discount = product.price - (product.price / 100) * product.discount
    const priceWithDiscount = `${utils.formatMoneyToString(discount)} CHF`

    return {
      ...product,
      title,
      price,
      priceWithDiscount,
      totalPrice: `${utils.formatMoneyToString(
        discount * product.quantity,
      )} CHF`,
    }
  })

  return (
    <>
      {!matches && (
        <Grid line>
          <Header>{t('shoppingCartPage.image')}</Header>
          <Header>{t('shoppingCartPage.product')}</Header>
          <Header>{t('shoppingCartPage.price')}</Header>
          <Header>{t('common.quantity')}</Header>
          <Header>{t('shoppingCartPage.total')}</Header>
          <Header>{t('shoppingCartPage.remove')}</Header>
        </Grid>
      )}
      {products.map((product, i, array) => (
        <Grid
          key={product.id}
          line={i + 1 !== array.length}
          error={product.isSoldOut}
        >
          <Square>
            <Image src={`${assets}${product.image}`} />
          </Square>
          <Values>
            <Typography variant="title">{product.brand}</Typography>
            <Typography variant="subtitle1">{product.title}</Typography>
            <Variants>
              {product.variant?.split('/').map((variant, i) => (
                <Variant key={variant}>
                  <span>{product.labels[i]}:&nbsp;</span>
                  <span>{variant}</span>
                </Variant>
              ))}
              {matches && (
                <Variant>
                  <span>Price:&nbsp;</span>
                  {product.priceWithDiscount ? (
                    <>
                      <span
                        css={`
                          margin-right: 4px;
                          text-decoration: line-through;
                        `}
                      >
                        {product.price}
                      </span>
                      <span
                        css={`
                          font-weight: 700;
                          color: #84368b;
                        `}
                      >
                        {product.priceWithDiscount}
                      </span>
                    </>
                  ) : (
                    <span>{product.price}</span>
                  )}
                </Variant>
              )}
            </Variants>
            {product.isSoldOut && (
              <SoldOut>{t('shoppingCartPage.soldOut')}</SoldOut>
            )}
          </Values>
          {!matches &&
            (product.priceWithDiscount ? (
              <Values>
                <Typography
                  variant="title"
                  css={`
                    color: #84368b;
                  `}
                >
                  {product.priceWithDiscount}
                </Typography>
                <Typography
                  variant="subtitle1"
                  css={`
                    text-decoration: line-through;
                  `}
                >
                  {product.price}
                </Typography>
              </Values>
            ) : (
              <Typography variant="title">{product.price}</Typography>
            ))}
          <Select>
            {matches && <label>{t('common.quantity')}</label>}
            <select
              value={product.quantity}
              onChange={e => {
                dispatch({
                  type: 'update',
                  product: {
                    ...data[i],
                    quantity: e.target.value,
                  },
                })
              }}
            >
              {Array.from({ length: product.total }, (_, x) => x + 1).map(
                value => (
                  <option key={value} value={value}>
                    {`${value}x`}
                  </option>
                ),
              )}
            </select>
          </Select>
          <ProductTotal>
            {matches && (
              <Typography variant="title">
                {t('shoppingCartPage.total')}:
              </Typography>
            )}
            <Typography variant={matches ? 'subtitle1' : 'title'}>
              {product.totalPrice}
            </Typography>
          </ProductTotal>
          <Action>
            <CloseButton
              onClick={() => {
                dispatch({
                  type: 'remove',
                  id: product.id,
                  variant: product.variant,
                })
              }}
            >
              <CloseIcon />
            </CloseButton>
          </Action>
        </Grid>
      ))}
      <ShippingInfo>
        <ShippingIcon />
        <div>{t('shoppingCartPage.shippingInfo')}</div>
      </ShippingInfo>
      <Total>
        <TotalValue>
          {shipping ? (
            <div>{t('common.totalWithShipping')}:&nbsp;</div>
          ) : (
            <div>{t('shoppingCartPage.total')}:&nbsp;</div>
          )}
          <div>{`${utils.formatMoneyToString(total + shipping)} CHF`}</div>
        </TotalValue>
        {!!shipping && (
          <>
            <ShippingText variant="subtitle2">
              {t('common.freeShipping')}
            </ShippingText>
          </>
        )}
        <Button as={Link} to="/checkout">
          {t('shoppingCartPage.buyNow')}
        </Button>
      </Total>
    </>
  )
}

export default function ShoppingCart() {
  const { t } = useTranslation()
  const { state } = useShoppingCart()
  const categories = state.products.reduce((acc, product) => {
    acc.push(...product.categories.map(({ name }) => name))
    return acc
  }, [])

  return (
    <>
      <Container>
        <div
          css={`
            display: flex;
            justify-content: center;
            margin-bottom: 64px;
          `}
        >
          <Typography variant="h2" as="h2">
            {t('shoppingCartPage.shoppingCart')}
          </Typography>
        </div>
        {!!state.products.length && <Cart products={state.products} />}
      </Container>
      {!!categories.length && <RelatedProducts categories={categories} />}
    </>
  )
}
