import React from 'react'
import styled from 'styled-components'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  HorizontalLogo,
  ShoppingCartButton,
  LanguageButton,
  RightIcon,
  Button as BaseButton,
  MenuIcon,
  CloseIcon,
  useMediaQuery,
} from './'
import SearchField from './SearchField'

const TopWrapper = styled.div`
  padding: 16px 0;
  background-color: #84368b;
`

const Top = styled.div`
  display: flex;
  align-items: center;
  max-width: 1114px;
  margin: 0 auto;

  @media (max-width: 1114px) {
    margin: 0 32px;
  }
`

const Logo = styled(RouterLink).attrs({ to: '/' })`
  flex: 1;

  svg {
    max-width: 242px;
    margin-right: 16px;
  }
`

const Controls = styled.div`
  display: flex;
  align-items: center;

  & > :not(:last-child) {
    margin-right: 8px;
  }
`

const Search = styled(SearchField)`
  min-width: 320px;
`

const Button = styled(BaseButton)`
  padding: 16px;
  font-weight: 700;
`

const Navigation = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
  border-bottom: 1px solid #b8b8b8;

  @media (max-width: 600px) {
    flex-direction: column;
    justify-content: flex-start;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    border-bottom: none;
  }
`

const Link = styled(RouterLink)`
  display: flex;
  align-items: center;
  padding: 16px 16px;
  font-family: inherit;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1;
  color: #181818;

  span {
    margin-right: 2px;
  }

  svg {
    width: 16px;
    height: 16px;
    fill: #181818;
  }

  @media (max-width: 600px) {
    justify-content: center;
    width: 100%;
    padding: 24px 16px;
    font-size: 1.25rem;
    border-bottom: 1px solid #b8b8b8;

    svg {
      display: none;
    }
  }
`

const navigation = [
  { name: 'menu.boys', to: '/products/boys' },
  { name: 'menu.girls', to: '/products/girls' },
  { name: 'menu.baby', to: '/products/baby' },
  { name: 'menu.shoes', to: '/products/shoes' },
  { name: 'menu.accessories', to: '/products/accessories' },
  { name: 'menu.toys-and-gifts', to: '/products/toys-and-gifts' },
  { name: 'menu.sale', to: '/products/sale' },
]

export default function Menu() {
  const { t } = useTranslation()
  const matches = useMediaQuery('(max-width: 600px)')
  const { pathname } = useLocation()
  const [open, setOpen] = React.useState(false)

  React.useEffect(() => {
    setOpen(false)
  }, [pathname])

  return (
    <header>
      <TopWrapper>
        <Top>
          <Logo>
            <HorizontalLogo />
          </Logo>
          <Controls>
            {!matches && <Search />}
            <ShoppingCartButton />
            <LanguageButton />
            {matches && (
              <Button onClick={() => setOpen(!open)}>
                {open ? <CloseIcon /> : <MenuIcon />}
              </Button>
            )}
          </Controls>
        </Top>
      </TopWrapper>
      <nav
        css={`
          position: relative;
        `}
      >
        {(!matches || open) && (
          <Navigation>
            {navigation.map(({ name, to }) => (
              <Link key={to} to={to}>
                <span>{t(name)}</span>
                <RightIcon />
              </Link>
            ))}
          </Navigation>
        )}
      </nav>
    </header>
  )
}
