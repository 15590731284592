import React from 'react'
import { useTranslation } from 'react-i18next'
import { useShoppingCart } from '../providers'
import { Typography, DoneIcon } from '../elements'

export default function PaymentSucceeded() {
  const { t } = useTranslation()
  const { state, dispatch } = useShoppingCart()

  if (state.products.length) {
    dispatch({ type: 'update-all', products: [] })
  }

  return (
    <div
      css={`
        max-width: 1114px;
        margin: 32px auto;

        @media (max-width: 1114px) {
          margin: 32px 32px;
        }
      `}
    >
      <div
        css={`
          display: flex;
          justify-content: center;
          margin: 32px 0 48px 0;
        `}
      >
        <Typography variant="h2" as="h2">
          {t('paymentSucceed.succeed')}
        </Typography>
      </div>
      <div
        css={`
          display: flex;
          align-items: center;

          @media (max-width: 1114px) {
            flex-direction: column;
          }
        `}
      >
        <DoneIcon
          css={`
            width: 72px;
            height: 72px;
            flex-shrink: 0;
            fill: #84368b;
          `}
        />
        <div
          css={`
            margin: 0 0 0 32px;
            font-family: inherit;
            font-weight: 500;
            font-size: 1rem;
            text-align: center;
            line-height: 1.5;

            @media (max-width: 1114px) {
              margin: 16px 0 0 0;
            }
          `}
        >
          {t('paymentSucceed.description')}
        </div>
      </div>
    </div>
  )
}
