import React from 'react'
import * as yup from 'yup'
import styled, { css } from 'styled-components'
import { gql, useApolloClient } from '@apollo/client'
import { Navigate } from 'react-router-dom'
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js'
import { useTranslation } from 'react-i18next'
import { utils, useForm } from '@kidzzzlugano/core'
import { useShoppingCart, useConfig } from '../providers'
import { Typography, Square, Image, StripeIcon, Button } from '../elements'

const Container = styled.div`
  max-width: 1114px;
  margin: 32px auto;

  @media (max-width: 1114px) {
    margin: 32px 32px;
  }
`

const Content = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: 'products form';
  padding: 0 32px 32px 32px;
  border-bottom: 1px solid #e3e3e3;

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      'form'
      'products';
    padding: 0;
  }
`

const Products = styled.div`
  grid-area: products;
`

const TotalLabel = styled(Typography)`
  margin-bottom: 4px;
  font-size: 0.875rem;
`

const TotalText = styled(Typography)`
  margin-bottom: 24px;
  font-weight: 700;
  font-size: 2.125rem;
`

const Product = styled.div`
  display: flex;
  margin-bottom: 16px;
`

const ProductImage = styled(Square)`
  width: 64px;
`

const ProductDescription = styled.div`
  align-self: center;
  margin-left: 8px;

  & > :not(:last-child) {
    margin-bottom: 4px;
  }
`

const ProductVariants = styled(Typography)`
  font-size: 0.75rem;
`

const PoweredBy = styled(Typography)`
  display: flex;
  margin-top: 32px;
  font-size: 0.75rem;

  a {
    color: #959595;
  }

  svg {
    fill: #959595;
  }
`

const Form = styled.form`
  grid-area: form;

  div {
    margin-bottom: 16px;
  }

  label {
    display: block;
    margin: 0 0 14px 16px;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1;
    color: #000;
  }

  button {
    width: 100%;
    margin-top: 32px;
    padding: 18px;
  }
`

const Input = styled.input`
  width: 100%;
  padding: 16px 24px;
  margin: 0;
  font-family: inherit;
  font-weight: 700;
  font-size: 1rem;
  color: #000;
  border: 1px solid #c6c6c6;
  border-radius: 32px;
  background-color: transparent;

  ::placeholder {
    color: #c6c6c6;
  }

  &:focus {
    outline: none;
  }

  ${({ error }) =>
    error &&
    css`
      border: 1px solid #fa755a;
    `}
`

const StripeInput = styled.div`
  padding: 18px 24px 0 24px;
  color: #000;
  border: 1px solid #c6c6c6;
  border-radius: 32px;
`

const SubmitButton = styled(Button)`
  ${({ disabled }) =>
    disabled &&
    css`
      background-color: #c6c6c6;
      color: #fff;
    `}
`

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#000',
      fontFamily: 'inherit',
      fontWeight: 700,
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#959595',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
}

const CHECKOUT = gql`
  query Checkout($products: [CheckoutProductInput!]!, $email: String!) {
    checkout(products: $products, email: $email) {
      ... on CheckoutNotFound {
        errors {
          id
          variant
        }
      }
      ... on Checkout {
        secret
      }
    }
  }
`

const schema = yup.object().shape({
  name: yup.string().required(),
  surname: yup.string().required(),
  email: yup.string().email().required(),
  address: yup.string().required(),
  city: yup.string().required(),
  postalCode: yup.string().required(),
})

function CheckoutForm({ total }) {
  const { t } = useTranslation()
  const client = useApolloClient()
  const { state, dispatch } = useShoppingCart()
  const stripe = useStripe()
  const elements = useElements()
  const [error, setError] = React.useState()
  const [success, setSuccess] = React.useState()

  const form = useForm(
    {},
    {
      schema,
      onSubmit: values => {
        return new Promise(async (resolve, reject) => {
          if (!stripe || !elements) {
            resolve()
          }

          try {
            const { data } = await client.query({
              query: CHECKOUT,
              variables: {
                products: state.products.map(product => ({
                  id: product.id,
                  quantity: parseInt(product.quantity),
                  variant: product.variant,
                })),
                email: values.email,
              },
            })

            if (data.checkout.errors) {
              const map = data.checkout.errors.reduce((acc, error) => {
                acc[error.id] = error
                return acc
              }, {})

              dispatch({
                type: 'update-all',
                products: state.products.map(product => {
                  if (!map[product.id]) {
                    return product
                  }

                  if (
                    !product.variant ||
                    product.variant === map[product.id].variant
                  ) {
                    return {
                      ...product,
                      isSoldOut: true,
                    }
                  }

                  return product
                }),
              })

              return
            }

            const result = await stripe.confirmCardPayment(
              data.checkout.secret,
              {
                payment_method: {
                  card: elements.getElement(CardElement),
                  billing_details: {
                    name: `${values.name} ${values.surname}`,
                    email: values.email,
                    address: {
                      line1: values.address,
                      city: values.city,
                      postal_code: values.postalCode,
                    },
                  },
                },
              },
            )

            if (result.error) {
              setError(result.error.message)
            } else if (result.paymentIntent.status === 'succeeded') {
              setSuccess(true)
            }
          } catch (e) {
            setError(e.message)
          } finally {
            resolve()
          }
        })
      },
    },
  )

  if (success) {
    return <Navigate to="/payment-succeeded" />
  }

  return (
    <Form {...form.getFormProps()}>
      <div>
        <label>{t('checkoutPage.name')}</label>
        <Input type="text" {...form.getFieldProps('name')} />
      </div>
      <div>
        <label>{t('checkoutPage.surname')}</label>
        <Input type="text" {...form.getFieldProps('surname')} />
      </div>
      <div>
        <label>{t('checkoutPage.email')}</label>
        <Input type="text" {...form.getFieldProps('email')} />
      </div>
      <div>
        <label>{t('checkoutPage.address')}</label>
        <Input type="text" {...form.getFieldProps('address')} />
      </div>
      <div>
        <label>{t('checkoutPage.postalCode')}</label>
        <Input type="text" {...form.getFieldProps('postalCode')} />
      </div>
      <div>
        <label>{t('checkoutPage.city')}</label>
        <Input type="text" {...form.getFieldProps('city')} />
      </div>
      <div>
        <label>{t('checkoutPage.country')}</label>
        <Input type="text" value={t('checkoutPage.switzerland')} disabled />
      </div>
      <div>
        <label>{t('checkoutPage.card')}</label>
        <StripeInput>
          <CardElement options={CARD_ELEMENT_OPTIONS} />
        </StripeInput>
      </div>
      <SubmitButton
        {...form.getSubmitButtonProps()}
        disabled={form.isSubmitting}
      >
        {`${t('checkoutPage.pay')} ${utils.formatMoneyToString(total)} CHF`}
      </SubmitButton>
      {error && <div>{error.message}</div>}
    </Form>
  )
}

export default function Checkout() {
  const { t, i18n } = useTranslation()
  const { assets } = useConfig()
  const { state } = useShoppingCart()

  if (
    !state.products.length ||
    state.products.some(product => product.isSoldOut)
  ) {
    return <Navigate to="/shopping-cart" />
  }

  const total = state.products.reduce((total, product) => {
    total += product.discount
      ? product.quantity *
        (product.price - (product.price / 100) * product.discount)
      : product.quantity * product.price
    return total
  }, 0)
  const shipping = total > 19500 ? 0 : 1400

  return (
    <Container>
      <div
        css={`
          display: flex;
          justify-content: center;
          margin-bottom: 64px;
        `}
      >
        <Typography variant="h2" as="h2">
          {t('checkoutPage.checkout')}
        </Typography>
      </div>
      <Content>
        <Products>
          {shipping ? (
            <>
              <TotalLabel>{t('common.totalWithShipping')}</TotalLabel>
              <TotalLabel variant="subtitle2">
                {t('common.freeShipping')}
              </TotalLabel>
            </>
          ) : (
            <TotalLabel>{t('common.total')}</TotalLabel>
          )}
          <TotalText>
            {`${utils.formatMoneyToString(total + shipping)} CHF`}
          </TotalText>
          {state.products.map(product => (
            <Product key={product.id}>
              <ProductImage>
                <Image src={`${assets}${product.image}`} />
              </ProductImage>
              <ProductDescription>
                <Typography variant="title">
                  {`${product.brand} (${product.quantity}x)`}
                </Typography>
                <Typography variant="subtitle1">
                  {
                    product.title.find(({ lang }) => lang === i18n.language)
                      .value
                  }
                </Typography>
                {product.variant && (
                  <ProductVariants>
                    {product.variant.split('/').map((variant, i) => (
                      <React.Fragment key={variant}>
                        <span>{product.labels[i]}:&nbsp;</span>
                        <span>{variant}&nbsp;&nbsp;</span>
                      </React.Fragment>
                    ))}
                  </ProductVariants>
                )}
              </ProductDescription>
            </Product>
          ))}
          <PoweredBy>
            <div
              css={`
                padding-right: 16px;
                border-right: 1px solid #959595;
              `}
            >
              <a
                href="https://stripe.com"
                rel="noopener noreferrer"
                target="_blank"
              >
                <div
                  css={`
                    display: flex;
                    align-items: center;
                  `}
                >
                  {t('checkoutPage.poweredBy')}&nbsp;
                  <StripeIcon />
                </div>
              </a>
            </div>
            <div
              css={`
                display: flex;
                align-items: center;
                height: 15px;
                padding: 0 0 0 16px;
              `}
            >
              <a
                href="https://stripe.com/checkout/terms"
                rel="noopener noreferrer"
                target="_blank"
              >
                {t('checkoutPage.terms')}
              </a>
            </div>
            <div
              css={`
                display: flex;
                align-items: center;
                height: 15px;
                padding: 0 16px;
              `}
            >
              <a
                href="https://stripe.com/privacy"
                rel="noopener noreferrer"
                target="_blank"
              >
                {t('checkoutPage.privacy')}
              </a>
            </div>
          </PoweredBy>
        </Products>
        <CheckoutForm total={total + shipping} />
      </Content>
    </Container>
  )
}
