import React from 'react'

export default function SearchIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path d="M0 0h24v24H0z" fill="none" />
      <path
        d="M11 2a9 9 0 11-9 9 9 9 0 019-9zm0 16a7 7 0 10-7-7 7 7 0 007 7zm8.49.07l2.82 2.83-1.41 1.41-2.83-2.83z"
        fill="#ffea00"
      />
    </svg>
  )
}
