import React from 'react'
import styled, { css } from 'styled-components'
import { BusinessHoursIcon, PhoneIcon, AddressIcon, MailIcon } from './'

const Container = styled.div`
  font-family: inherit;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #000;

  ${props =>
    props.variant === 'dark' &&
    css`
      color: #fff;

      @media (max-width: 600px) {
        display: grid;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
      }
    `}

  & > div:not(:last-child) {
    margin: 0 0 16px 0;
  }
`

const Contact = styled.div`
  display: flex;
  align-items: center;

  svg {
    width: 21px;
    height: 21px;
    margin-right: 8px;
  }
`

export default function Contacts({ variant = 'light' }) {
  const fill = variant === 'light' ? '#ddd' : '#7B3A88'

  return (
    <Container variant={variant}>
      <Contact>
        <BusinessHoursIcon fill={fill} />
        <span>Mon. - Fri. 9:30 - 18:30 Sat. 10:00 - 18.00</span>
      </Contact>
      <Contact>
        <PhoneIcon fill={fill} />
        <span>+410764752828</span>
      </Contact>
      <Contact>
        <AddressIcon fill={fill} />
        <span>Address: Via della Posta 3, Lugano 6900</span>
      </Contact>
      <Contact>
        <MailIcon fill={fill} />
        <span>info@kidzzzlugano.ch</span>
      </Contact>
    </Container>
  )
}
