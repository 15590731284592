import React from 'react'

export default function ArrowNextIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 45.71 45.71"
      {...props}
    >
      <circle fill="#ffea00" cx="22.85" cy="22.85" r="22.85" />
      <path d="M28.89,22.34,20.3,13.78a.72.72,0,1,0-1,1l8.08,8.05-8.08,8.06a.71.71,0,0,0,0,1,.7.7,0,0,0,.51.22.71.71,0,0,0,.51-.21l8.59-8.57a.72.72,0,0,0,.21-.51A.75.75,0,0,0,28.89,22.34Z" />
    </svg>
  )
}
