import React from 'react'
import { Routes, Route } from 'react-router-dom'
import {
  Home,
  Products,
  Product,
  ShoppingCart,
  Checkout,
  PaymentSucceeded,
} from './'
import { Menu, AboutStore, Footer } from '../elements'

function Layout() {
  return (
    <>
      <Menu />
      <Routes>
        <Route path="products" element={<Products />} />
        <Route path="products/:category" element={<Products />} />
        <Route path="product/:id" element={<Product />} />
        <Route path="shopping-cart" element={<ShoppingCart />} />
        <Route path="checkout" element={<Checkout />} />
        <Route path="payment-succeeded" element={<PaymentSucceeded />} />
      </Routes>
    </>
  )
}

export default function Main() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/*" element={<Layout />} />
      </Routes>
      <AboutStore />
      <Footer />
    </>
  )
}
