import React from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { SearchIcon } from './'

const TextField = styled.div`
  position: relative;

  input {
    width: 100%;
    padding: 16px 24px;
    margin: 0;
    font-family: inherit;
    font-weight: 700;
    font-size: 1.125rem;
    color: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 32px;
    background-color: transparent;

    ::placeholder {
      color: #ffffff;
    }

    &:focus {
      outline: none;
    }
  }

  svg {
    position: absolute;
    top: 16px;
    right: 24px;
    width: 24px;
    height: 24px;
  }
`

export default function SearchField({ className }) {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [search, setSearch] = React.useState('')

  function submitHandler(e) {
    e.preventDefault()
    navigate(`/products?search=${search}`)
  }

  return (
    <form onSubmit={submitHandler}>
      <TextField className={className}>
        <input
          value={search}
          onChange={e => setSearch(e.target.value)}
          placeholder={t('common.searchSomething')}
        />
        <SearchIcon onClick={submitHandler} />
      </TextField>
    </form>
  )
}
