import React from 'react'

export default function ArrowPrevIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 45.71 45.71"
      {...props}
    >
      <circle fill="#ffea00" cx="22.85" cy="22.85" r="22.85" />
      <path d="M16.82,23.36l8.59,8.57a.72.72,0,0,0,1,0,.7.7,0,0,0,0-1l-8.07-8.06,8.07-8a.72.72,0,0,0,0-1,.75.75,0,0,0-.51-.21.71.71,0,0,0-.51.21l-8.59,8.56a.75.75,0,0,0-.21.51A.72.72,0,0,0,16.82,23.36Z" />
    </svg>
  )
}
