import React from 'react'
import styled from 'styled-components'
import { gql, useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import GoToShopButton from './GoToShopButton'
import { Typography, ProductPreview } from '../../elements'

const GET_PRODUCTS = gql`
  {
    getProducts(input: { skip: 0, limit: 12 }) {
      id
      title {
        lang
        value
      }
      price
      discount
      brand
      images
    }
  }
`

const Container = styled.section`
  background-color: #f1f1f1;
`

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 50px 32px;
`

export default function NewArrivals() {
  const { t } = useTranslation()
  const { loading, error, data } = useQuery(GET_PRODUCTS, {
    fetchPolicy: 'cache-and-network',
  })

  if (error) {
    return null
  }

  if (loading && !data?.getProducts) {
    return null
  }

  if (data.getProducts.length < 4) {
    return null
  }

  return (
    <Container>
      <Wrapper>
        <Typography variant="h2" as="h2">
          {t('homePage.newArrivals')}
        </Typography>
      </Wrapper>
      <ProductPreview data={data.getProducts} />
      <Wrapper>
        <GoToShopButton />
      </Wrapper>
    </Container>
  )
}
