import React from 'react'

export default function PhoneIcon(props) {
  const { fill, ...rest } = props

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...rest}>
      <path fill="none" d="M0,0H24V24H0Z" />
      <path
        fill={fill}
        d="M9.37,10.68a10.41,10.41,0,0,0,4,4l.88-1.23a1,1,0,0,1,1.3-.3,11.32,11.32,0,0,0,4.58,1.36,1,1,0,0,1,.92,1v4.46a1,1,0,0,1-.9,1c-.53,0-1.06.08-1.6.08A15.5,15.5,0,0,1,3,5.5C3,5,3,4.43,3.08,3.9a1,1,0,0,1,1-.9H8.54a1,1,0,0,1,1,.92A11.32,11.32,0,0,0,10.9,8.5a1,1,0,0,1-.3,1.3ZM6.84,10l1.9-1.36A13.49,13.49,0,0,1,7.65,5H5c0,.17,0,.33,0,.5A13.49,13.49,0,0,0,18.5,19H19V16.35a13.49,13.49,0,0,1-3.67-1.09L14,17.16a15.22,15.22,0,0,1-1.58-.75l-.06,0a12.48,12.48,0,0,1-4.7-4.7l0-.06A15.22,15.22,0,0,1,6.84,10Z"
      />
    </svg>
  )
}
