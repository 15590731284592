import styled from 'styled-components'

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 56px;
  font-family: inherit;
  font-weight: 900;
  font-size: 0.875rem;
  line-height: 1;
  color: #000000;
  background-color: #ffea00;
  border-radius: 32px;
  cursor: pointer;
`

export default Button
