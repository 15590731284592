import React from 'react'

export default function MailIcon(props) {
  const { fill, ...rest } = props

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...rest}>
      <path fill="none" d="M0,0H24V24H0Z" />
      <path
        fill={fill}
        d="M2.24,6.85l9.25-5.54a1,1,0,0,1,1,0l9.24,5.55a.49.49,0,0,1,.24.42V20a1,1,0,0,1-1,1H3a1,1,0,0,1-1-1V7.28A.51.51,0,0,1,2.24,6.85ZM4,8.13V19H20V8.13l-8-4.8L4,8.13Zm8.06,5.57,5.3-4.46,1.28,1.53L12.07,16.3,5.36,10.77,6.64,9.23Z"
      />
    </svg>
  )
}
