import React from 'react'

const Context = React.createContext(null)

export function useShoppingCart() {
  const context = React.useContext(Context)
  if (!context) {
    throw new Error(
      'useShoppingCart must be used within a ShoppingCartProvider',
    )
  }
  return context
}

const json = window.localStorage.getItem('cart')
const data = json && JSON.parse(json)
const initialState = data || { products: [] }

function reducer(state, action) {
  switch (action.type) {
    case 'add':
      return {
        ...state,
        products: [...state.products, action.product],
      }
    case 'update':
      return {
        ...state,
        products: state.products.map(product => {
          if (action.product.variant) {
            return action.product.id === product.id &&
              action.product.variant === product.variant
              ? action.product
              : product
          }

          return action.product.id === product.id ? action.product : product
        }),
      }
    case 'update-all':
      return {
        ...state,
        products: action.products,
      }
    case 'remove':
      return {
        ...state,
        products: state.products.filter(product => {
          if (action.variant) {
            return !(
              action.id === product.id && action.variant === product.variant
            )
          }

          return action.id !== product.id
        }),
      }
    default:
      throw new Error()
  }
}

export default function ShoppingCartProvider({ children }) {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  React.useEffect(() => {
    window.localStorage.setItem('cart', JSON.stringify(state))
  }, [state])

  return (
    <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>
  )
}
