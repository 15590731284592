import { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle`
  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
  }

  *, *::before, *::after {
    box-sizing: inherit;
  }

  body {
    margin: 0;
    font-family: "Muli", "Helvetica", "Arial", sans-serif;;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: normal;
    letter-spacing: normal;
    color: #151413;
    background-color: #fff;
  }

  button {
    appearance: none;
    border: none;
    padding: 0;
    outline: none;
    background-color: transparent;
  }

  ul {
    list-style-type: none;
  }

  a {
    text-decoration: none;
  }
`

export default GlobalStyles
