import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Typography } from '../../elements'
import { useTranslation } from 'react-i18next'
import boysImg from './boys.png'
import girlsImg from './girls.png'
import babyImg from './baby.png'
import GoToShopButton from './GoToShopButton'

const Container = styled.section`
  position: relative;
`

const Shape = styled.div`
  position: absolute;
  top: -64px;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 50% 50% 0 0/100% 100% 0 0;
    transform: scaleX(2.8);
  }

  @media (max-width: 600px) {
    &::before {
      transform: scaleX(4);
    }
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 30px;
`

const Categories = styled.div`
  display: flex;
  position: relative;

  & > a:not(:last-child) {
    margin: 0 30px 0 0;
  }

  & > a {
    position: relative;
  }

  @media (max-width: 600px) {
    flex-direction: column;
    & > a:not(:last-child) {
      margin: 0 0 30px 0;
    }
  }
`

const CategoryTitle = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin-top: 55%;
  font-family: inherit;
  font-weight: 900;
  font-size: 2.75rem;
  line-height: 1;
  color: #000;
`

const categories = [
  { src: girlsImg, text: 'girlClothes', link: '/products/girls' },
  { src: boysImg, text: 'boyClothes', link: '/products/boys' },
  { src: babyImg, text: 'babyClothes', link: '/products/baby' },
]

export default function Home() {
  const { t } = useTranslation()

  return (
    <Container>
      <Shape />
      <Content>
        <Typography
          variant="h2"
          css={`
            margin: 0 0 50px 0;
          `}
        >
          {t('homePage.popularCategories')}
        </Typography>
        <Categories>
          {categories.map(({ src, text, link }, i) => (
            <Link key={link} to={link}>
              <img width="100%" height="auto" key={link} src={src} />
              <CategoryTitle>
                <div>{t(`homePage.${text}.title`)}</div>
              </CategoryTitle>
            </Link>
          ))}
        </Categories>
        <div
          css={`
            position: relative;
            margin: 32px 0 48px 0;
          `}
        >
          <GoToShopButton />
        </div>
      </Content>
    </Container>
  )
}
