import React from 'react'
import styled from 'styled-components'
import { Link as RouterLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { VerticalLogo, Contacts } from './'

const Container = styled.div`
  display: grid;
  gap: 0 32px;
  grid-template-columns: minmax(0, 0.6fr) minmax(0, 1.4fr) repeat(
      2,
      minmax(0, 1fr)
    );
  grid-template-areas: 'logo description contacts links';
  max-width: 1114px;
  margin: 0 auto;

  @media (max-width: 1114px) {
    margin: 0 32px;
  }

  @media (max-width: 600px) {
    gap: 32px;
    grid-template-columns: minmax(0, 1fr);
    grid-template-areas:
      'logo'
      'description'
      'contacts'
      'links';
  }
`

const Logo = styled(RouterLink)`
  grid-area: logo;

  svg {
    max-height: 160px;
  }
`

const Description = styled.div`
  grid-area: description;
  font-family: inherit;
  font-weight: 400;
  font-size: 0.875rem;
  color: #fff;

  @media (max-width: 600px) {
    text-align: center;
  }
`

const ContactsWrapper = styled.div`
  grid-area: contacts;

  @media (max-width: 600px) {
    justify-self: center;
  }
`

const Links = styled.div`
  grid-area: links;
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 600px) {
    padding-top: 32px;
    border-top: 2px solid #96749a;
  }
`

const Link = styled(RouterLink)`
  width: 50%;
  font-family: inherit;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #96749a;

  @media (max-width: 600px) {
    text-align: center;
    line-height: 2.5;
    width: 33.33%;
  }
`

const links = [
  { name: 'menu.boys', to: '/products/boys' },
  { name: 'menu.girls', to: '/products/girls' },
  { name: 'menu.baby', to: '/products/baby' },
  { name: 'menu.brands', to: '/products/brands' },
  { name: 'menu.shoes', to: '/products/shoes' },
  { name: 'menu.accessories', to: '/products/accessories' },
  { name: 'menu.toys-and-gifts', to: '/products/toys-and-gifts' },
  { name: 'menu.sale', to: '/products/sale' },
]

export default function Footer() {
  const { t } = useTranslation()

  return (
    <footer
      css={`
        padding: 50px 0;
        background-color: #5d2662;
      `}
    >
      <Container>
        <Logo to="/">
          <VerticalLogo />
        </Logo>
        <Description>
          {`${t('common.title')}. ${t('common.subtitle')}`}
        </Description>
        <ContactsWrapper>
          <Contacts variant="dark" />
        </ContactsWrapper>
        <Links>
          {links.map(({ name, to }) => (
            <Link key={to} to={to}>
              {t(name)}
            </Link>
          ))}
        </Links>
      </Container>
    </footer>
  )
}
