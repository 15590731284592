import styled from 'styled-components'

const Square = styled.div`
  width: 100%;
  position: relative;

  &::before {
    content: '';
    padding-bottom: 100%;
    display: inline-block;
    vertical-align: top;
  }
`

export default Square
