import React from 'react'

export default function ShoppingCartIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path fill="none" d="M0,0H24V24H0Z" />
      <path
        fill="#000"
        d="M4,16V4H2V2H5A1,1,0,0,1,6,3V15H18.44l2-8H8V5H21.72a1,1,0,0,1,1,1,1,1,0,0,1,0,.24l-2.5,10a1,1,0,0,1-1,.76H5A1,1,0,0,1,4,16Zm2,7a2,2,0,1,1,2-2A2,2,0,0,1,6,23Zm12,0a2,2,0,1,1,2-2A2,2,0,0,1,18,23Z"
      />
    </svg>
  )
}
