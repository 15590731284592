import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { utils } from '@kidzzzlugano/core'
import { useConfig } from '../providers'
import { Typography, ArrowPrevIcon, ArrowNextIcon, useMediaQuery } from './'

const Products = styled.div`
  display: grid;
  grid-template-columns:
    32px minmax(0, 255px) minmax(0, 255px) minmax(0, 255px) minmax(0, 255px)
    32px;
  gap: 16px 32px;
  grid-template-areas:
    'prev product-0 product-1 product-2 product-3 next'
    '. title-0 title-1 title-2 title-3 .';
  max-width: 1234px;
  margin: 0 auto;

  @media (max-width: 1234px) {
    margin: 0 16px;
  }

  @media (max-width: 600px) {
    grid-template-columns: 32px minmax(0, 255px) minmax(0, 255px) 32px;
    gap: 8px 16px;
    grid-template-areas:
      'prev product-0 product-1 next'
      '. title-0 title-1 .';
    max-width: 100%;
    margin: 0 16px;
  }
`

const PrevProduct = styled.div`
  grid-area: prev;
  place-self: center;
`

const NextProduct = styled.div`
  grid-area: next;
  place-self: center;
`

const Product = styled(Link)`
  grid-area: ${props => `product-${props.index}`};
  background-image: ${props => `url(${props.src})`};
  background-size: cover;
  background-position: 50%;

  &::before {
    content: '';
    padding-bottom: 100%;
    display: inline-block;
    vertical-align: top;
  }
`

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`

const Title = styled(Link)`
  grid-area: ${props => `title-${props.index}`};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > :not(:last-child) {
    margin-bottom: 8px;
  }
`

const Discount = styled.div`
  display: flex;
  align-items: center;

  & > :first-child {
    margin-right: 5px;
    text-decoration: line-through;
  }

  & > :nth-child(2) {
    font-weight: 700;
    color: #84368b;
  }
`

export default function ProductPreview({ data }) {
  const { i18n } = useTranslation()
  const { assets } = useConfig()
  const matches = useMediaQuery('(max-width: 600px)')
  const [page, setPage] = React.useState(1)
  const itemsPerPage = matches ? 2 : 4

  const wrappedPage = utils.wrap(
    page,
    0,
    Math.floor(data.length / itemsPerPage),
  )
  const start = wrappedPage * itemsPerPage
  const end = (wrappedPage + 1) * itemsPerPage

  const products = data.slice(start, end).map(product => ({
    ...product,
    price: `${utils.formatMoneyToString(product.price)} CHF`,
    priceWithDiscount:
      product.discount &&
      `${utils.formatMoneyToString(
        product.price - (product.price / 100) * product.discount,
      )} CHF`,
    image: product?.images[0],
    title: product.title.find(title => title.lang === i18n.language).value,
  }))

  return (
    <Products>
      <PrevProduct>
        <button type="button" onClick={() => setPage(page - 1)}>
          <ArrowPrevIcon width="100%" />
        </button>
      </PrevProduct>
      {products.map((product, i) => (
        <React.Fragment key={product.id}>
          <Product
            to={`/product/${product.id}`}
            index={i}
          >
            <Image src={`${assets}${product.image}`} />
          </Product>
          <Title to={`/product/${product.id}`} index={i}>
            <Typography variant="title">{product.brand}</Typography>
            <Typography variant="subtitle1">{product.title}</Typography>
            {product.priceWithDiscount ? (
              <Discount>
                <Typography variant="subtitle2">{product.price}</Typography>
                <Typography variant="subtitle2">
                  {product.priceWithDiscount}
                </Typography>
              </Discount>
            ) : (
              <Typography variant="subtitle2">{product.price}</Typography>
            )}
          </Title>
        </React.Fragment>
      ))}
      <NextProduct>
        <button type="button" onClick={() => setPage(page + 1)}>
          <ArrowNextIcon width="100%" />
        </button>
      </NextProduct>
    </Products>
  )
}
