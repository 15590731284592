import React from 'react'

export default function AddressIcon(props) {
  const { fill, ...rest } = props

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...rest}>
      <path fill="none" d="M0,0H24V24H0Z" />
      <path
        fill={fill}
        d="M12,20.9,17,16a7,7,0,1,0-9.9,0Zm0,2.83L5.64,17.36a9,9,0,1,1,12.72,0ZM12,13a2,2,0,1,0-2-2A2,2,0,0,0,12,13Zm0,2a4,4,0,1,1,4-4A4,4,0,0,1,12,15Z"
      />
    </svg>
  )
}
