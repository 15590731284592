import React from 'react'

export default function BusinessHoursIcon(props) {
  const { fill, ...rest } = props

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...rest}>
      <path fill="none" d="M0,0H24V24H0Z" />
      <path
        fill={fill}
        d="M9,1V3h6V1h2V3h4a1,1,0,0,1,1,1V20a1,1,0,0,1-1,1H3a1,1,0,0,1-1-1V4A1,1,0,0,1,3,3H7V1Zm11,9H4v9H20Zm-5,1.14,1.41,1.41L11.5,17.5,8,14l1.42-1.41,2.12,2.12L15,11.14ZM7,5H4V8H20V5H17V6H15V5H9V6H7Z"
      />
    </svg>
  )
}
