import React from 'react'
import styled from 'styled-components'
import ReactMapboxGl, { Layer, Feature } from 'react-mapbox-gl'
import { useTranslation } from 'react-i18next'
import img1 from './img_001.jpg'
import img2 from './img_002.jpg'
import img3 from './img_003.jpg'
import img4 from './img_004.jpg'
import pin from './pin.png'
import { Typography } from '../'
import Contacts from '../Contacts'

const image = new Image()
image.src = pin

const Map = ReactMapboxGl({
  accessToken:
    'pk.eyJ1IjoicHJ1ZG9sZnMiLCJhIjoiY2thMm1wODZkMDRzZzNlbGtnMGdvZGN1ZCJ9.aCYUNYiKPlc584uxKHER3g',
  interactive: false,
})

const images = [
  { id: 1, src: img1 },
  { id: 2, src: img2 },
  { id: 3, src: img3 },
  { id: 4, src: img4 },
]

const Container = styled.div`
  max-width: 1114px;
  margin: 50px auto;

  @media (max-width: 1114px) {
    margin: 50px 32px;
  }
`

const Products = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  gap: 32px 32px;
  grid-template-areas:
    'title title'
    'cover description'
    'cover contacts'
    'cover thumbnails';

  @media (max-width: 600px) {
    gap: 16px;
    grid-template-columns: minmax(0, 1fr);
    grid-template-areas:
      'title'
      'description'
      'cover'
      'thumbnails'
      'contacts';
  }
`

const Title = styled(Typography).attrs({
  as: 'h2',
  variant: 'h2',
})`
  grid-area: title;
  justify-self: center;
  margin-bottom: 32px;
`

const Cover = styled.div`
  grid-area: cover;
  background-image: ${props => `url(${props.src})`};
  background-size: cover;
  background-position: 50%;

  &::before {
    content: '';
    padding-bottom: 60%;
    display: inline-block;
    vertical-align: top;
  }
`

const Description = styled(Typography).attrs({ as: 'p' })`
  grid-area: description;
  font-weight: 700;
`

const StoreContacts = styled(Contacts)`
  grid-area: contacts;
`

const Thumbnails = styled.div`
  grid-area: thumbnails;
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(4, minmax(0, 1fr));
`

const Thumbnail = styled.div`
  background-image: ${props => `url(${props.src})`};
  background-size: cover;
  background-position: 50%;

  &::before {
    content: '';
    padding-bottom: 80%;
    display: inline-block;
    vertical-align: top;
  }
`

const ShopMap = React.memo(function ShopMap() {
  return (
    <Map
      style="mapbox://styles/mapbox/streets-v11"
      containerStyle={{
        width: '100vw',
        height: 'calc(100vh / 2)',
      }}
      zoom={[14]}
      center={[8.9526, 46.00457]}
    >
      <Layer
        type="symbol"
        id="marker"
        layout={{ 'icon-image': 'my-image' }}
        images={['my-image', image]}
      >
        <Feature coordinates={[8.9526, 46.00457]} />
      </Layer>
    </Map>
  )
})

export default function AboutStore() {
  const { t } = useTranslation()
  const [selected, setSelected] = React.useState(1)
  const cover = images.find(image => image.id === selected)

  return (
    <section
      css={`
        background-color: #fff;
      `}
    >
      <Container>
        <Products>
          <Title>{t('common.aboutStore')}</Title>
          <Cover src={cover.src} />
          <Description>
            {`${t('common.title')}. ${t('common.subtitle')}`}
          </Description>
          <StoreContacts />
          <Thumbnails>
            {images.map(({ id, src }) => (
              <Thumbnail key={id} src={src} onClick={() => setSelected(id)} />
            ))}
          </Thumbnails>
        </Products>
      </Container>
      <ShopMap />
    </section>
  )
}
