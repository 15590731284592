import styled, { css } from 'styled-components'

const Typography = styled.div`
  position: relative;
  margin: 0;
  font-family: inherit;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1;
  color: #000;

  ${props =>
    props.variant === 'h2' &&
    css`
      display: inline;
      font-weight: 800;
      font-size: 1.875rem;

      &::after {
        content: ' ';
        position: absolute;
        left: 50%;
        top: calc(100% + 20px);
        width: 26px;
        height: 5px;
        background-color: #dbdbdb;
        transform: translateX(-50%);
      }
    `}

    ${props =>
      props.variant === 'title' &&
      css`
        font-weight: 700;
        font-size: 1.125rem;
      `}

    ${props =>
      props.variant === 'subtitle1' &&
      css`
        font-weight: 500;
        font-size: 0.875rem;
        color: #000;
      `}

    ${props =>
      props.variant === 'subtitle2' &&
      css`
        font-weight: 500;
        font-size: 0.875rem;
        color: #9d9d9d;
      `}
`

export default Typography
